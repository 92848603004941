var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('div',{staticClass:"row m0 announcement-container"},[(_vm.announcement.message)?_c('h1',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.announcement.message.html.replace(/\u2028/g, '')
      .replace(/(<p><\/p>)/g, '<p aria-hidden=true></p>')
      .replace(/(<h1><\/h1>)/g, '<h1 aria-hidden=true></h1>')
      .replace(/(<h2><\/h2>)/g, '<h2 aria-hidden=true></h2>')
      .replace(/(<h3><\/h3>)/g, '<h3 aria-hidden=true></h3>')
      .replace(/(<h4><\/h4>)/g, '<h4 aria-hidden=true></h4>')
      .replace(/(<h5><\/h5>)/g, '<h5 aria-hidden=true></h5>')
      .replace(/(<h6><\/h6>)/g, '<h6 aria-hidden=true></h6>')),expression:"announcement.message.html.replace(/[\\u{2028}]/gu, '')\n      .replace(/(<p><\\/p>)/g, '<p aria-hidden=true></p>')\n      .replace(/(<h1><\\/h1>)/g, '<h1 aria-hidden=true></h1>')\n      .replace(/(<h2><\\/h2>)/g, '<h2 aria-hidden=true></h2>')\n      .replace(/(<h3><\\/h3>)/g, '<h3 aria-hidden=true></h3>')\n      .replace(/(<h4><\\/h4>)/g, '<h4 aria-hidden=true></h4>')\n      .replace(/(<h5><\\/h5>)/g, '<h5 aria-hidden=true></h5>')\n      .replace(/(<h6><\\/h6>)/g, '<h6 aria-hidden=true></h6>')"}],staticClass:"announcement-text"}):_vm._e(),_vm._v(" "),_c('button',{staticClass:"announcement-close",attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.closeClick()}}},[_c('div',{staticClass:"focusable__content",attrs:{"tabindex":"-1"}},[_c('SvgImage',{staticClass:"announcement-close-img",attrs:{"svg-file":"AnnouncementBar_CloseButton_X.svg","image-alt":"Announcement bar close button","image-title":"Announcement bar close button"}})],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }