





































import Vue from 'vue'
import VueCookies from 'vue-cookies'
import SvgImage from '~/components/SvgImage.vue'

Vue.use(VueCookies)

export default Vue.extend({
  components: {
    SvgImage
  },
  props: {
    announcement:
    {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      show: true
    }
  },
  methods: {
    closeClick () {
      const result = { ids: [`${this.announcement.id}`] }

      const read = this.$cookies.get('announcements_read')
      if (read != null) {
        result.ids = result.ids.concat(read.ids)
      }

      this.$cookies.set('announcements_read', result, '12h')
      this.show = false
    }
  }
})
