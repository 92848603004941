


























































































































































































import Vue from 'vue'
import VueCookies from 'vue-cookies'
import getEventCategories from '~/apollo/queries/getEventCategories.gql'
import getAnnouncements from '~/apollo/queries/getAnnouncements.gql'
import Announcement from '~/components/Announcement.vue'
import SvgImage from '~/components/SvgImage.vue'

Vue.use(VueCookies)

export default Vue.extend({
  components: {
    Announcement,
    SvgImage
  },
  async fetch () {
    const { data } = await this.$apolloProvider.defaultClient.query({
      query: getEventCategories
    })
    this.eventCategories = data.eventCategories

    const announcementsQuery = await this.$apolloProvider.defaultClient.query({
      query: getAnnouncements
    })
    this.allAnnouncements = announcementsQuery.data.announcements
  },
  data () {
    return {
      drawer: false,
      eventCategories: [] as any,
      allAnnouncements: [] as any,
      headerHeight: 0,
      subMenuOpened: -1,
      isMobile: false
    }
  },
  computed: {
    items (): Array<any> {
      return [
        {
          title: 'Events',
          route: 'events',
          children: [...[{ title: 'See all', slug: '', id: '' }], ...(this.eventCategories != null ? this.eventCategories : [])]
        },
        { title: 'Stories', route: 'stories' },
        { title: 'Press', route: 'press' },
        { title: 'About', route: 'about' },
        { title: 'Redbird', link: 'https://redbird.la/' },
        { title: 'Inquire', route: 'inquire' }
      ]
    },
    announcements (): Array<any> {
      if (process.client) {
        const read = this.$cookies.get('announcements_read')
        if (read == null) {
          return this.allAnnouncements
        }
        return this.allAnnouncements.filter((c: any) => !read.ids.includes(c.id))
      }

      return []
    },
    currentSubMenu (): Number {
      return this.subMenuOpened
    }
  },
  watch: {
    drawer (val) {
      if (!val) {
        this.subMenuOpened = -1
      }
    }
  },
  mounted () {
    this.isMobile = window.innerWidth < 661
    window.addEventListener('resize', this.windowResize)

    const self = this
    const resizeObserver = new ResizeObserver(function () {
      const el = document.getElementById('header-appbar')
      self.headerHeight = el == null ? self.headerHeight : el.offsetHeight
    })
    resizeObserver.observe((document.getElementById('header-appbar') as any))
  },
  destroyed () {
    window.removeEventListener('resize', this.windowResize)
  },
  methods: {
    openSubMenu (index: number) {
      if (!this.isMobile) {
        this.subMenuOpened = index
      }
    },
    toggleSubMenu (index: number) {
      this.subMenuOpened = this.subMenuOpened === index ? -1 : index
    },
    windowResize () {
      this.isMobile = window.innerWidth < 661
    },
    closeMenuOnTab (index: number) {
      if (index === this.items.length - 1) {
        this.drawer = false
      }
    }
  }
})
