








export default {
  props: {
    imageAlt:
    {
      type: String,
      default: ''
    },
    svgFile: {
      type: String,
      default: null
    }
  },
  computed: {
    path (): any {
      const self = this as any
      return require(`~/assets/img/${self.svgFile}`)
    }
  }
}
