import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=fe0b44ca&"
import script from "./Header.vue?vue&type=script&lang=ts&"
export * from "./Header.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules_dev/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Announcement: require('/vercel/path0/src/components/Announcement.vue').default,SvgImage: require('/vercel/path0/src/components/SvgImage.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules_dev/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAppBar,VList,VListItem,VListItemContent,VListItemTitle,VNavigationDrawer,VToolbarTitle})
